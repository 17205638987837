<template>
  <v-container class="pt-12" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-container>
          <v-row>
            <v-col cols="12"><h1>Post Editor</h1></v-col>
          </v-row>
          <v-divider class="py-2 my-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details="auto"
                label="제목"
                class="font-weight-bold title"
                v-model="post.post_title"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="ml-auto">
              <v-select
                outlined
                hide-details="auto"
                label="Post 타입"
                v-model="post.post_type"
                :items="['Notice', 'Announcement', 'KnowledgeBase', 'Article']"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="post.post_content"
                :extensions="extensions"
                placeholder="내용"
                :disableInputRules="true"
                :disablePasteRules="true"
              />
            </v-col>
          </v-row>
          <v-divider class="py-2 my-3"></v-divider>
          <v-row>
            <v-col>
              <p class="pa-0 ma-0 mb-1 font-weight-bold">Tag 설정</p>
              <v-card class="pa-0" tile elevation="0" outlined min-height="35">
                <v-card-text class="pa-0">
                  <v-layout>
                    <v-col
                      cols="auto"
                      class="pa-1"
                      v-for="tag in post.TB_POST_TAGs"
                      :key="tag.post_tag_id"
                    >
                      <v-chip
                        small
                        close
                        close-icon="mdi-close"
                        @click:close="tagRemove(tag)"
                        >{{ tag.post_tag }}</v-chip
                      >
                    </v-col>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                dense
                hide-details="auto"
                class="py-0 my-0"
                label="Tag 추가 (입력후 엔터)"
                v-model="addTagFrm"
                @keydown.enter="tagCreate"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!isModifyMode">
            <v-col class="pt-3">
              <Attachments v-model="post.attachments" />
            </v-col>
          </v-row>
          <v-row v-if="isModifyMode">
            <v-col cols="auto" class="py-0 pt-3">
              <p class="pa-0 ma-0 mb-1 font-weight-bold">첨부파일 추가제거</p>
            </v-col>
            <v-col class="ml-auto py-0 pt-3" cols="auto">
              <v-btn x-small color="info" @click="chooseFiles"> 추가 </v-btn>
            </v-col>
            <v-col cols="12">
              <input
                id="attachment"
                name="attachment"
                ref="attachment"
                type="file"
                @change="newAttachment"
                hidden
              />
              <DataGrid
                :data="post.attachments"
                :filterable="false"
                :pageSize="5"
                :pagination="true"
                :virtualScroll="true"
                :clickToEdit="true"
                :columnResizing="true"
                style="width: 100%"
              >
                <GridColumnGroup>
                  <GridHeaderRow>
                    <GridColumn
                      field="file_name"
                      title="File Name"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_size"
                      title="File Size"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                    <GridColumn
                      field="file_mimetype"
                      title="Mime Type"
                      align="center"
                      :sortable="true"
                    >
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
                <GridColumnGroup :frozen="true" width="400" align="right">
                  <GridHeaderRow>
                    <GridColumn
                      field="createdAt"
                      title="Created Time"
                      align="center"
                      :sortable="true"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        {{ toKorTime(scope.row.createdAt) }}
                      </template>
                    </GridColumn>

                    <GridColumn
                      title="Option"
                      :filterable="false"
                      align="center"
                      size="200"
                    >
                      <template slot="body" slot-scope="scope">
                        <LinkButton
                          iconCls="icon-remove"
                          class="mx-1"
                          @click="removeAttachment(scope.row)"
                        >
                          Remove
                        </LinkButton>
                      </template>
                    </GridColumn>
                  </GridHeaderRow>
                </GridColumnGroup>
              </DataGrid>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-btn tile color="#FF4D00" dark @click="backToList">
                <v-icon class="mr-2">mdi-post-outline</v-icon>
                목록으로
              </v-btn>
            </v-col>
            <v-col v-if="isAdmin" cols="auto" class="ml-auto">
              <v-btn tile color="success" dark @click="savePost">
                <v-icon class="mr-2">mdi-check</v-icon>
                저장
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "@jwmsg/tiptap-vuetify";
import { getPosts, putPosts, newPosts } from "@/api/Post";
import Attachments from "@/components/Views/NewPost/Attachments.vue";
import { remove, uploadInPost } from "@/api/Files";
export default {
  props: {
    post_id: {
      type: String,
      default: null,
    },
  },
  components: { TiptapVuetify, Attachments },
  mounted() {
    this.getPosts();
  },
  methods: {
    toKorTime(time) {
      const date = new Date(time);
      return date.toLocaleString("Seoul");
    },
    async removeAttachment(item) {
      this.btnLoading = true;
      const res = await remove(item.file_uuid);
      if (res.success) {
        alert("삭제되었습니다.");
        this.$router.go();
      }
      this.btnLoading = false;
    },
    chooseFiles() {
      const attachment = this.$refs.attachment;
      attachment.click();
    },
    async newAttachment() {
      const attachment = this.$refs.attachment;
      const formData = new FormData();
      if (attachment.files.length > 0) {
        const uploadFile = attachment.files[0];
        if (uploadFile.size > 2048 * 1024 * 1024) {
          this.btnLoading = false;
          return alert("2GB 이하의 파일만 업로드가 가능합니다.");
        }
        alert(
          "업로드 용량이 클수록 오래 기다려 주셔야 합니다.\n업로드 로딩이 끝날때 까지 대기해 주시기 바랍니다."
        );
        formData.append("file", uploadFile);
        const data = await uploadInPost(formData, this.post.post_id);
        if (data.success) {
          this.$router.go();
        }
        this.$emit("input", this.attachmentItems);
      } else {
        this.selectedFile = null;
      }
      this.btnLoading = false;
    },
    backToList() {
      this.$router.push(`/posts/list`);
    },
    btnToSave() {},
    async getPosts() {
      if (!this.isModifyMode) return;
      const data = await getPosts({ post_id: this.post_id });
      if (data.success) {
        if (data.data.length == 0) {
          alert("존재하지 않는 Post 입니다.");
          this.$router.go(-1);
        }
        this.post = data.data[0];
        this.post.attachments = this.post.TB_JOIN_POST_FILEs.map(
          (e) => e.TB_DATA_FILE_LIST
        ).filter((e) => e != null);
      }
    },
    async tagRemove(tag) {
      const idx = this.post.TB_POST_TAGs.findIndex(
        (e) => e.post_tag == tag.post_tag
      );
      if (idx > -1) this.post.TB_POST_TAGs.splice(idx, 1);
    },
    async tagCreate() {
      if (this.addTagFrm == "") return;
      if (this.addTagFrm == null) return;
      const idx = this.post.TB_POST_TAGs.findIndex(
        (e) => e.post_tag == this.addTagFrm
      );
      if (idx == -1) {
        if (this.post.TB_POST_TAGs == null) {
          this.post.TB_POST_TAGs = [];
        }
        this.post.TB_POST_TAGs.push({
          post_id: this.post_id,
          post_tag: this.addTagFrm,
        });
      }
      this.addTagFrm = "";
    },
    async savePost() {
      if (this.isModifyMode) {
        await this.putPost();
      } else {
        await this.newPost();
      }
    },

    async putPost() {
      const data = await putPosts(this.post);
      if (data.success) {
        alert("저장하였습니다.");
        this.$router.push(`/posts/view/${this.post_id}`);
      }
    },
    async newPost() {
      console.log(this.post);
      const data = await newPosts(this.post);
      if (data.success) {
        alert("저장하였습니다.");
        this.$router.push(`/posts/view/${data.data.post_id}`);
      }
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
    isModifyMode() {
      return this.post_id != null;
    },
  },
  data() {
    return {
      post: {
        TB_POST_TAGs: [],
        post_id: "",
        post_title: "",
        post_content: "",
        post_type: "Notice",
        attachments: [],
      },
      addTagFrm: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
};
</script>

<style>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 250px;
}
</style>
